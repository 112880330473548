import React from 'react';
import './Developer.css';
export default function Developer() {


    return (
        <p className='Developer'>
            © Copyright 2024
        </p>
    );
}
